.App {
  position: relative;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Ensure it's behind other content */
}

.content {
  position: relative;
  z-index: 1; 
  height: 100vh; 
  width: 100vw; /* Ensure it's above the background */
  /* Add any additional styling for your content */
}

@media (max-width: 1280px) { /* Adjust the max-width as needed for your breakpoint */
  .background-container,
  .content {
    height: 120vh; /* Increase the height when the screen is squished */
  }
}

html {
  scroll-behavior: smooth;
}
#home {
  padding-top: 50px; /* Adjust this value based on your navbar height */
   /* Negative margin to offset the padding */
}

.content2 {
  position: relative;
  z-index: 1;  
  width: 100vw; /* Ensure it's above the background */
  /* Add any additional styling for your content */
}

.footer-sec {
  z-index: 16;
  position: relative;
}