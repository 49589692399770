body {
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    
    overflow: auto;
    display: flex;
    font-family: 'Anton', sans-serif;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }
  
  .night {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw; 
    height: 100vh; 
    transform: rotateZ(45deg);
  }
  
  .shooting_star {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 2px;
    background: linear-gradient(-45deg, rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    border-radius: 999px;
    filter: drop-shadow(0 0 6px rgba(105, 155, 255, 1));
    animation: tail 7000ms ease-in-out infinite, shooting 7000ms ease-in-out infinite;
  }
  
  .shooting_star::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(45deg);
    border-radius: 100%;
    animation: shining 7000ms ease-in-out infinite;
  }
  
  .shooting_star::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), rgba(95, 145, 255, 1), rgba(0, 0, 255, 0));
    transform: translateX(50%) rotateZ(-45deg);
    border-radius: 100%;
    animation: shining 7000ms ease-in-out infinite;
  }
  
  /* This is the manual conversion of the @for loop */
  .shooting_star:nth-child(1) {
    top: calc(50% - 130px);
    left: calc(50% - 80px);
    animation-delay: 1000ms;
  }
  
  .shooting_star:nth-child(2) {
    top: calc(50% - 180px);
    left: calc(50% - 120px);
    animation-delay: 3000ms;
  }
  
  .shooting_star:nth-child(3) {
    top: calc(50% - 90px);
    left: calc(50% - 180px);
    animation-delay: 5000ms;
  }
  
  /* Add similar nth-child rules for the other shooting stars up to 20 */
  
  @keyframes tail {
    0% {
      width: 0;
    }
    30% {
      width: 100px;
    }
    100% {
      width: 0;
    }
  }
  
  @keyframes shining {
    0% {
      width: 0;
    }
    50% {
      width: 30px;
    }
    100% {
      width: 0;
    }
  }
  
  @keyframes shooting {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100vw);
    }
  }
  
  @keyframes sky {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg); /* 45 + 360 */
    }
  }
  